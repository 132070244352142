@charset "utf-8";
/* ============================================================ */
/* .contents-header */
/* ============================================================ */
.contents-header {
	flex: 0 0 auto;
	
	.contents-ttl {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 15px;
		background: #383838;
		background: #000;
		color: $white;
		font-size: $xl;
		line-height: 1.2;
	}
	
	.gnav {
		padding: 20px 15px 15px;
		background: #000;
		color: $white;

		.gnav__item {
			display: flex;
			align-items: center;
			padding: 15px 0;
			border-bottom: 1px solid rgba($white, 0.3);
			color: $white;
			font-size: $l;

			&:first-of-type {
				border-top: 1px solid rgba($white, 0.3);
			}

			&__icon {
				flex: 0 0 auto;
				margin: 0 10px;
				font-size: $xxs;
			}
		}
	}
}



/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	flex: 1 0 auto;
	padding: 15px 15px 30px;
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: rgba($white, 0.25);

	&--white {
		background: $white;
	}
	&--gray {
		background: #f9f9f9;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--answer1 {
		padding: 0 0 10px;
		background: none;
		border-bottom: 1px solid $red;
		color: $red;
		font-size: 1.5rem;
		line-height: 1.6;
	}
	&--answer2 {
		padding: 0 0 5px;
		background: none;
		border-bottom: 1px solid #00abe4;
		line-height: 1.6;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid $borderColor;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #d5d5d5;
		text-align: left;
		vertical-align: middle;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	
	
	&--sp-column1 {
		display: block;
		
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 40px;
	margin: 5px 0;
	padding: 0 30px;
	color: $textColor;
	text-align: center;
	line-height: 1.4;
	@include radius(3);
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--gray {
		background: linear-gradient(to bottom, #fafafa 0%, #e0e0e0 100%);
		border: 1px solid #d2d2d2;
	}
	&--green {
		color: $white;
		@include btn-grow($baseColor);
		&:hover { color: rgba($white, 0.8); }
	}
	&--pink { @include btn-grow($pink); }
	&--yellow { @include btn-grow($yellow); }
	&--lightgreen { @include btn-grow($green); }
	&--blue { @include btn-grow($blue); }
	&--black {
		background: $textColor;
	}
	&--shadow {
		box-shadow: 0 1px 1px rgba(#000, 0.08);
	}
	&--lg {
		width: 100%;
		height: 50px;
		font-weight: bold;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		height: 30px;
		padding: 0 10px;
		font-size: $xxs;
	}
	&--disabled,
	&:disabled {
		background: #dfdfdf;
		border: 1px solid $borderColor;
		color: rgba($textColor, 0.15);
		cursor: default;
	}
}

.btn-group {
	.btn {
		border-left-width: 0;
		@include radius(0);
		
		&:first-child {
			border-left-width: 1px;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}


input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

button {
	background: none;
	
	&[class^="btn"] {
		display: -webkit-inline-box;
		display: -moz-inline-box;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 15px 20px 15px 40px;
	background: rgba(#000, 0.08);
	cursor: pointer;
	line-height: 1.4;
	
	&__icon {
		left: 15px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
}



/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.hankaku {
	ime-mode: disabled;
}

input[type="text"],
input[type="password"]{
	height: 40px;
	padding: 0 10px;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: 1.6rem;
	appearance: none;
	@include radius(0);
}

.label-must {
	display: inline-block;
	right: 5px;
	padding: 5px;
	background: #dd0000;
	color: $white;
	font-size: $xxxs;
	line-height: 1;
	@include radius(2);
	text-align: center;
}


.selectbox {
	position: relative;
	height: 40px;
	background: #fff;
	border: 1px solid $borderColor;
	cursor: pointer;
	
	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		content: '';
		width: 40px;
		background: $baseColor;
		pointer-events: none;
	}
	&::after {
		position: absolute;
		top: 50%;
		right: 13px;
		content: "\f107";
		font-family: 'icomoon';
		color: #fff;
		font-size: 2.0rem;
		pointer-events: none;
		transform: translateY(-50%);
	}
	
	select {
		width: 100%;
		height: 38px;
		padding: 0 10px;
		color: $textColor;
		font-size: 1.6rem;
		cursor: pointer;
		appearance: none;
	}
}


/* radio
----------------------------------------------------------- */
.radio {
	display: block;
	
	input[type="radio"] {
		display: none;
	}

	input[type="radio"] + span {
		position: relative;
		padding: 0 0 0 25px;
		cursor: pointer;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}

		&::before {
			left: 0;
			background: rgba($white, 0.8);
			border: 2px solid #d2d2d2;
			@include circle(14);
		}
		
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}

.radio-btn {
	display: block;
	
	input[type="radio"] {
		display: none;
	}

	input[type="radio"] + span {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 25px;
		padding: 0 10px;
		background: linear-gradient(to bottom, #fafafa 0%, #e0e0e0 100%);
		border: 1px solid #d2d2d2;
		color: $textColor;
		cursor: pointer;
		line-height: 1.4;
		@include radius(3);
	}
	
	&:hover input[type="radio"] + span,
	input[type="radio"]:checked + span {
		background: linear-gradient(to bottom, #73c462 0%, $baseColor 100%);
		border-color: $baseColor;
		color: $white;
	}
	
	&--hint,
	&--voice {
		&:first-child {
			input[type="radio"] + span {
				border-right: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child {
			input[type="radio"] + span {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	&--pink {
		input[type="radio"] + span,
		input[type="radio"]:checked + span { @include radio-btn-grow($pink); }
		&:hover {
			input[type="radio"] + span,
			input[type="radio"] + span:checked { @include radio-btn-grow-hover($pink); }
		}
		&.radio-btn--disabled:hover {
			input[type="radio"] + span { @include radio-btn-grow($pink); }
		}
	}
	&--yellow {
		input[type="radio"] + span,
		input[type="radio"]:checked + span { @include radio-btn-grow($yellow); }
		&:hover {
			input[type="radio"] + span,
			input[type="radio"]:checked + span { @include radio-btn-grow-hover($yellow); }
		}
		&.radio-btn--disabled:hover {
			input[type="radio"] + span { @include radio-btn-grow($yellow); }
		}
	}
	&--lightgreen {
		input[type="radio"] + span,
		input[type="radio"]:checked + span { @include radio-btn-grow($green); }
		&:hover {
			input[type="radio"] + span,
			input[type="radio"]:checked + span { @include radio-btn-grow-hover($green); }
		}
		&.radio-btn--disabled:hover {
			input[type="radio"] + span { @include radio-btn-grow($green); }
		}
	}
	&--disabled {
		input[type="radio"] + span,
		input[type="radio"]:checked + span {
			background: #dfdfdf !important;
			border: 1px solid $borderColor !important;
			color: rgba($textColor, 0.15) !important;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	display: block;
	
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 25px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&::before {
			left: 0;
			width: 14px;
			height: 14px;
			content: '';
			background: rgba($white, 0.8);
			border: 2px solid $borderColor;
		}
		
		&::after {
			left: 2px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			opacity: 0;
		}
	}

	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}




/* textarea
----------------------------------------------------------- */
.textarea {
	width: 100%;
	background: transparent;
	font-size: $m;
	line-height: 1.6;
	appearance: none;
	@include radius(0);
}


/* error-block
----------------------------------------------------------- */
.error-block {
	background: #ffecec;
	border: 3px solid #bb1900;
	border-radius: 5px;
	padding: 15px 20px;
	margin: 0 0 15px;

	&__text {
		font-weight: bold;
		color: #bb1900;
		margin: 0 0 5px;
		font-size: 1.8rem;
	}

	ul {
		list-style-type: disc;
		padding: 0 0 0 20px;

		li {
			color: #bb1900;
			margin: 0 0 5px;
			line-height: 1.4;
			font-size: 1.6rem;
		}
	}
}

.error {
	color: #bb1900;
	display: block;
}


/* --------------------------------------------------- */
/* question */
/* --------------------------------------------------- */
.question {
	display: flex;
	margin: 0 0 20px;
	
	&__number {
		flex: 0 0 auto;
		position: relative;
		top: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 5px 0 0;
		background: $textColor;
		color: $white;
		font-size: $xxxs;
		@include circle(18);
	}
	&__text {
		flex: 1 1 auto;
		font-weight: bold;
	}
}


/* --------------------------------------------------- */
/* score-list */
/* --------------------------------------------------- */
.score-list {
	display: block;
	width: 100%;
	line-height: 1.6;
	
	tbody {
		display: block;
	}
	
	tr {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 10px;
		background: rgba($white, 0.2);
	}
	tr:nth-child(2n+1) {
		background: rgba(#000, 0.04);
	}
	.bg-gray {
		background: rgba(#000, 0.08)
	}
	&__jpn {
		display: block;
		width: 100%;
		margin: 0 0 10px;
		&__number {
			position: relative;
			top: 3px;
			flex: 0 0 auto;
			margin: 0 5px 0 0;
			background: $textColor;
			color: $white;
			font-size: $xxxs;
			text-align: center;
			line-height: 16px;
			@include circle(16);
		}
	}
	&__eng {
		display: block;
		width: 100%;
		margin: 0 0 15px;
		
		&__text {
			order: 1;
		}
		.checkbox {
			flex: 0 0 auto;
			order: 0;
		}
	}
	&__voice {
		display: block;
		flex: 1 1 auto;
		padding: 0 0 0 25px;
	}
	&__time {
		display: flex;
		align-items: center;
		margin: 0 10px;
		font-size: $xxs;
		.icon-schedule {
			margin: 0 3px 0 0;
		}
	}
	&__rank {
		display: block;
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 25px;
		@include radius(3);
		
		&--a { background: $pink; }
		&--b { background: $yellow; }
		&--c { background: $green; }
	}
	&__check {
		@media screen and (max-width: 360px) {
			width: 10%;
		}
	}
	&__date {
		flex: 0 0 auto;
		display: block;
		font-size: $xxs;
		@media screen and (max-width: 360px) {
			width: 90%;
		}
	}
	&__theme {
		flex: 1 1 auto;
		display: block;
		margin: 0 15px;
		@media screen and (max-width: 360px) {
			padding-left: 5%;
		}
	}
	&__average {
		background: rgba($baseColor, 0.2) !important;
		font-weight: bold;
		
		&__ttl {
			flex: 1 1 auto;
			display: block;
			padding-left: 30px;
		}
	}
}


/* --------------------------------------------------- */
/* theme-list */
/* --------------------------------------------------- */
.theme-list {
	@media print, screen and (min-width: 580px) {
		column-count: 2;
		column-gap: 3em;
		column-rule: 1px solid $borderColor;
	}
}



/* --------------------------------------------------- */
/* btn-fix-bottom */
/* --------------------------------------------------- */
.btn-fix-bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;
	padding: 15px 0;
	background: rgba(#000, 0.75);
}