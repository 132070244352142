@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	position: relative;
	flex: 0 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 15px 20px;
	background: rgba(#000, 0.1);
	
	.copyright {
		font-size: $xxs;
		text-align: center;
		letter-spacing: 0.05em;
	}
	
	.footer-logo {
		display: flex;
		align-items: center;
	}
}