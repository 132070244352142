@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	padding: 20px 0 20px;
	background: rgba(#000, 0.1);
	
	.copyright {
		font-size: $xxxs;
		letter-spacing: 0.02em;
	}
	.footer-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 15px;
	}
	.footer-logo2 {
		flex: 0 0 auto;
		width: 120px;
		margin: 0 0 15px;
	}
	
	.btn-fix & {
		padding-bottom: 90px;
	}
}