@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
body {
	background: url(/common/img/common/bg_body_dot.jpg) 0 0 repeat;
}

.wrap {
	display: flex;
	flex-direction: column;
	height: 100vh;
	padding: 60px 0 0;
	color: $textColor;
	font-family: "メイリオ", Meiryo UI, Meiryo, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: $s;
	line-height: 1.6;
}

.pc-view {
	display: none !important;
}

a {
	color: $linkColor;
}


#modal-menu,
#modal-score {
	display: none;
}