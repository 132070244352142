@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

body {
	background: url(/common/img/common/bg_body_dot.jpg) 0 0 repeat;
	font-family: "メイリオ", Meiryo UI, Meiryo, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.wrap {
	display: flex;
	flex-direction: column;
	height: 100vh;
	color: $textColor;
	font-size: $m;
	line-height: 1.8;
}

.sp-view {
	display: none !important;
}

a {
	color: $linkColor;
	
	&:hover {
		text-decoration: underline;
	}
}


#modal-menu,
#modal-score {
	display: none;
}