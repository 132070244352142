@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 30px;
	
	&.js-hiraku-header-fixed {
		position: static;
	}
	.header-logo1 {
		flex: 0 0 auto;
		padding: 5px 0 0;
	}
	.header-logo2 {
		width: 120px;
		padding: 0 0 5px;
	}
}
