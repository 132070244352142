@charset "utf-8";

$baseColor: #5bba47;
$textColor: #333;
$linkColor: #00abeb;
$borderColor: #dadada;

$white: #fff;
$red: #dd0000;
$pink: #ed849b;
$yellow: #e9b825;
$green: #a4cf57;
$blue: #7fc6e3;

$listening: #f36f21;
$reading: #99cc63;
$speaking: #eea320;
$writing: #7cc5ed;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin btn-grow($keyColor) {
	background: linear-gradient(to bottom, rgba($white, 0.15) 0%, rgba($white, 0) 100%);
	background-color: $keyColor;
	border: 1px solid $keyColor;
	
	&:hover {
		background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0) 100%);
		background-color: rgba($keyColor, 0.7);
		color: rgba($textColor, 0.7);
	}
}

@mixin radio-btn-grow($keyColor) {
	background: linear-gradient(to bottom, rgba($white, 0.15) 0%, rgba($white, 0) 100%);
	background-color: $keyColor;
	border: 1px solid $keyColor;
	color: $textColor;
}

@mixin radio-btn-grow-hover($keyColor) {
	background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0) 100%);
	background-color: rgba($keyColor, 0.7);
	border-color: $keyColor;
	color: rgba($textColor, 0.7);
}



/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

