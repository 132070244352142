@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	z-index: 100;
	display: flex;
	align-items: center;
	height: 60px;
	padding: 0 15px 0 10px;
	background: #fff;
	
	.header-menu {
		flex: 0 0 auto;
		display: flex;
		display: -webkit-inline-box;
		display: -moz-inline-box;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		margin: 0 10px 0 0;
		background: #000;
		color: $white;
		font-size: $l;
		@include radius(2);
	}
	.header-logo1 {
		text-align: center;
		
		@media screen and (max-width: 359px) {
			img {
				height: 20px !important;
			}
		}
	}
	
	&::after {
    position: absolute;
		left: 0;
		right: 0;
		bottom: -3px;
		content: '';
		height: 3px;
		background: linear-gradient(to bottom, rgba(#000, 0.15) 0%, rgba(#000, 0) 100%);
	}
	
	&.small {
		.header-logo1 {
			flex: 0 0 auto;
			text-align: center;
			
			& > * {
				height: 19px !important;
			}
		}
	}
}


.btn-header-fix {
	position: fixed;
	top: 17px;
	right: 10px;
	z-index: 100;
	transition: all $animation;
	
	.js-hiraku-offcanvas-body-left & {
		transform: translate(200%, 0);
	}
}